.project-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5%;
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    justify-content: space-between;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .project-container {
        padding-top: 4vh;
        flex-direction: column;
    }
}

@media only screen and (max-width: 425px) {

}