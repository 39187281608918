.pricing-element-container, .pricing-element-container-secondary {
    background-color: #7793ED;
    color: white;
    box-sizing: border-box;
    padding: 1vh 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 60vh;
    width: 35%;
}

.pricing-element-container-secondary {
    height: 50vh;
    width: 25%;
}



.price-button {
    color: white;
    background: transparent;
    border: solid 1px white;
    width: 80%;
    align-self: center;
    border-radius: 60px;
    font-weight: 500;
    font-size: 1.2vw;
    /* cursor: pointer; */
    padding: 15px;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, white 50%);
    transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
    opacity: 0;
    cursor: default;
}

.pricing-element-container-secondary > .price-button {
    font-size: 1vw;
}

.price-button:hover {
  color:#7793ED;
  background-color:white;
  background-position: -100% 100%;
}

.pricing-list-element {
    list-style-type: none;
    font-weight: 300;
    font-size: 1.2vw;
    margin-bottom: 15px;
    user-select: none;
}

@media only screen and (max-width: 1024px) {

    .price-button {
        width: 90%;
        font-size: 1.6vw;
    }
    
    .pricing-element-container-secondary > .price-button {
        font-size: 1.3vw;
    }
    
    .pricing-list-element {
        font-size: 1.5vw;
    }
}

@media only screen and (max-width: 768px) {
    .price-button {
        font-size: 2vw;
    }
    
    .pricing-element-container-secondary > .price-button {
        font-size: 1.7vw;
    }
    
    .pricing-list-element {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 425px) {

    .pricing-element-container, .pricing-element-container-secondary {
        width: 100%;
        margin-top: 2vh;
    }
    
    .pricing-element-container-secondary {
        width: 100%;
    }

    .price-button {
        font-size: 3.4vw;
    }
    
    .pricing-element-container-secondary > .price-button {
        font-size: 3.4vw;
    }
    
    .pricing-list-element {
        font-size: 3.4vw;
    }
}