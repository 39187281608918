.team-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 10vh 20% !important;
    overflow: hidden;
    margin-bottom: 5vh;
}

.team-container > .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
    padding-top: 5vh;
}