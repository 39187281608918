.home-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
}

.home-left-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 100px 80px;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.home-left-side > h1 {
    font-weight: 500;
    font-size: 6vw;
    line-height: 92.7%;
}

.home-left-side > h2 {
    font-weight: 300;
    font-size: 3vw;
    margin-top: -0px;
}

.home-left-side > p {
    font-weight: 300;
    font-size: 1.5vw;
    max-width: 60%;
    margin-top: 20px;
}

.home-left-side > button {
    color: white;
    background: transparent;
    border: solid 1px #7793ED;
    color: #7793ED;
    width: 56%;
    align-self: center;
    border-radius: 60px;
    font-weight: 500;
    font-size: 1.8vw;
    cursor: pointer;
    padding: 15px;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, #7793ED 50%);
    transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
    margin-top: 90px;
}

.home-left-side > button:hover {
  color:rgba(255, 255, 255, 1);
  background-color:#7793ED;
  background-position: -100% 100%;
}

.home-video {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-video > .video-player {
    margin-top: -16vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transform: translateX(50%);
    transition: transform 0.4s ease-out;
    z-index: 0;
}

.home-video > .video-player:hover {
    position: relative;
    transform: translateX(0);
    cursor: pointer;
}

.home-video > .video-player > .icon {
    font-size: 5vw;
}

@media only screen and (max-width: 1024px) {
    .home-video > .video-player {
        transform: translateX(25%);
    }

    .home-left-side > h1 {
        font-size: 8vw;
    }
    
    .home-left-side > h2 {
        font-size: 4vw;
    }
    
    .home-left-side > p {
        font-size: 2.5vw;
        max-width: 90%;
    }

    .home-left-side > button {
        border: solid 2px #7793ED;
        width: 78%;
        font-size: 2.4vw;
    }
}

@media only screen and (max-width: 768px) {
    .home-container {
        flex-direction: column;
    }

    .home-video > .video-player {
        transform: translateX(0);
    }

    .home-video {
        width: 100%;
    }

    .home-left-side {
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 100px 0px;
    }

    .home-left-side > h1 {
        font-size: 8vw;
    }
    
    .home-left-side > h2 {
        font-size: 4vw;
    }
    
    .home-left-side > p {
        font-size: 2.5vw;
        max-width: 90%;
    }

    .home-left-side > button {
        margin-top: 20px;
        margin-bottom: 10vh;
    }
}

@media only screen and (max-width: 425px) {
    .home-video {
        width: 90%;
        align-self: center;
    }

    .home-left-side > h1 {
        font-size: 12vw;
    }
    
    .home-left-side > h2 {
        font-size: 6vw;
    }
    
    .home-left-side > p {
        font-size: 4vw;
        max-width: 100%;
    }

    .home-left-side > button {
        width: 100%;
        font-size: 3.6vw;
        margin-bottom: 0;
    }
}