.navbar-container {
    height: 9vh;
    display: flex;
    position: sticky;
    top: 0px;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #F2F5FE;
    padding: 0 40px;
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    z-index: 10;
}

.navbar-button {
    box-sizing: border-box;
    margin-left: 3%;
    background: transparent;
    border-radius: 28px;
    border: solid 1px #7793ED;
    color: #7793ED;
    font-weight: 500;
    padding: 5px 25px;
    font-size: 1vw;
    cursor: pointer;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, #7793ED 50%);
    transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
}

.navbar-button:hover {
  color:rgba(255, 255, 255, 1);
  background-color:#7793ED;
  background-position: -100% 100%;
}

.navbar-logo {
    height: 3.5vh;
}

.navbar-links-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.navbar-link {
    position: relative;
    font-size: 1vw;
    color: #7793ED;
    font-weight: 500;
    font-style: normal;
    text-decoration: none;
    margin-left: 3%;
    font-weight: 500;
}

.navbar-link::after {
    content: "";
    border-bottom: solid 2px #7793ED;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.25s;
}
.navbar-link:hover::after {
    transform-origin: left;
    transform: scaleX(1);
}

.opened-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #F2F5FE;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.menu-opener {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 6vw;
}

@keyframes navbar-link {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}

@media only screen and (max-width: 1024px) {
    .navbar-link {
        font-size: 1.4vw;
    }

    .navbar-button {
        font-size: 1.4vw;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-link {
        font-size: 2vw;
    }

    .navbar-button {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 425px) {
    .navbar-links-container {
        flex-direction: column;
    }

    .navbar-container {
        flex-direction: column;
    }

    .navbar-link {
        font-size: 8vw;
    }

    .navbar-button {
        font-size: 8vw;
    }

    .navbar-exit {
        font-size: 10vw;
        position: absolute;
        left: 10px;
        top: -10px;
    }
}

.input-search-container {
    width: 60vw;
    height: 7vh;
    position: relative;
}

.input-search {
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    border: 2px solid #7793ED;
    border-radius: 46px;
    text-align: center;
    padding-left: 20px;
    padding-right: 60px;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 1.5em;
    color: black;
    text-align: center;
}

.input-search::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.2);
    text-align: center;
}

.input-search:-moz-placeholder { /* Firefox 18- */
    color: rgba(0, 0, 0, 0.2);
    text-align: center;
}

.input-search::-moz-placeholder {  /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.2);
    text-align: center;
}

.input-search:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.2);
    text-align: center;
}

.input-search:focus{
    outline: none;
}