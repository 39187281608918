.button-container:hover {
    background-color: rgb(151, 205, 109);
}

.button-text {
    font-weight: 700;
    font-size: 1vw;
    align-self: center;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .button-text {
        font-size: 1.3vw;
    }
}

@media screen and (max-width: 768px) {
    .button-text {
        font-size: 1.6vw;
    }
}

@media screen and (max-width: 600px) {
    .button-text {
        font-size: 3vw;
    }
}

@media screen and (max-width: 480px) {
    .button-text {
        font-size: 4vw;
    }
}

.button-container {
    color: white;
    background: transparent;
    border: solid 1px #7793ED;
    color: #7793ED;
    width: 56%;
    align-self: center;
    border-radius: 60px;
    font-weight: 500;
    font-size: 1.8vw;
    cursor: pointer;
    padding: 15px;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, transparent 50%, #7793ED 50%);
    transition: background-position .3s cubic-bezier(0.19, 1, 0.22, 1) .1s, color .5s ease 0s, background-color .5s ease;
}

.button-container:hover {
  color:rgba(255, 255, 255, 1);
  background-color:#7793ED;
  background-position: -100% 100%;
}