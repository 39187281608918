.member-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    text-align: center;
}

.member-container > img {
    margin-bottom: 5px;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1024px) {
    
}

@media only screen and (max-width: 768px) {
    
}

@media only screen and (max-width: 425px) {
    .member-container {
        width: 40%;
        margin-top: 2vh;
    }
}